<template>
  <div class="clo-top--wrapper" :class="{headerFixed: headerFixed}" ref="cloTop">
    <div class="clo-top">
      <el-row>
        <el-col :span="4">
          <div class="clo-top__logo">
            <img class="logo__image" :src="Logo" alt="">
          </div>
        </el-col>
        <el-col :span="12" :offset="8">
          <div class="clo-top__bar">
            <div class="clo-top__bar-item " :class="{'clo-top__bar-item--active': actived===0}" @click.stop="goModelPage(0)">
              <div class="item__title">关于我的</div>
              <div class="item__subtitle">About</div>
            </div>
            <div class="clo-top__bar-item" :class="{'clo-top__bar-item--active': actived===1}" @click.stop="goModelPage(1)">
              <div class="item__title">产品功能</div>
              <div class="item__subtitle">features</div>
            </div>
            <div class="clo-top__bar-item" :class="{'clo-top__bar-item--active': actived===2}" @click.stop="goModelPage(2)">
              <div class="item__title">公司历程</div>
              <div class="item__subtitle">history</div>
            </div>
            <div class="clo-top__bar-item" :class="{'clo-top__bar-item--active': actived===3}" @click.stop="goModelPage(3)">
              <div class="item__title">联系我们</div>
              <div class="item__subtitle">Contact</div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Logo from '@/assets/logo.png';

export default {
  props: {
    headerFixed: {
      type: Boolean,
      default: false,
    },
    actived: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      Logo,
    };
  },
  methods: {
    goModelPage(num) {
      this.$emit('update:actived', num);
      const body = this.$parent.$refs.clo;
      const obj = {
        0: 699,
        1: 2079,
        2: 5683,
        3: 6520,
      };
      body.scrollTop = obj[num];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo-top--wrapper.headerFixed {
  width: calc(100% - 17px) !important;
  position: fixed;
  top: 0;
  right: 17px;
  z-index: 999;
}
.clo-top--wrapper {
  @include flex-row(center);
  width: 100%;
  height: 110px;
  background-color: #FFFFFF;
  .clo-top {
    width: 1200px;
    height: 100%;
    &__logo {
      @include flex-row(center);
      width: 205px;
      height: 110px;
      .logo__image {
        width: 100%;
        height: 70px;
      }
    }
    &__bar {
      @include flex-row(space-between);
      height: 110px;
      position: relative;
      &-item {
        @include flex-column(space-between,flex-start);
        cursor: pointer;
        padding-left: 10px;
        border-left: 2px solid #222222;
        text-align: left;
        height: 44px;
        &.clo-top__bar-item--active {
          @include flex-column(space-between,flex-start);
          padding-left: 10px;
          border-left: 2px solid #F39800;
          text-align: left;
          height: 44px;
          .item__title {
            @include font-init(22px, #F39800, 800);
          }
          .item__subtitle {
            @include font-init(16px, #F39800, 800);
          }
        }
        .item__title {
          @include font-init(22px, #222222, 500);
        }
        .item__subtitle {
          @include font-init(16px, #222222, 500);
        }
      }
    }
  }
}
</style>
