<template>
  <div class="clo-send-message--wrapper">
    <div class="clo-send-message">
      <div class="clo-send-message__title">
        <div class="clo-send-message__title--text">发送信息</div>
        <div class="clo-send-message__title--line"></div>
      </div>
      <div class="clo-send-message__form">
        <div class="clo-send-message__form--item">
          <input class="item__input" v-model="dataForm.linkman" type="text" placeholder="姓名">
        </div>
        <div class="clo-send-message__form--item">
          <input class="item__input" v-model="dataForm.tel" type="text" placeholder="手机号">
        </div>
        <div class="clo-send-message__form--item">
          <input class="item__input" v-model="dataForm.content" type="text" placeholder="留言">
        </div>
        <div class="clo-send-message__form--submit" :class="{'disabled':isDisabled }" @click="handleSubmit">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataForm: {
        tel: '',
        linkman: '',
        content: '',
      },
      isDisabled: false,
    };
  },
  methods: {
    async handleSubmit() {
      if (this.isDisabled) {
        return;
      }
      this.isDisabled = true;
      if (!this.dataForm.linkman) {
        this.$message.error('请先填写姓名');
        this.isDisabled = false;
        return;
      }
      if (!this.dataForm.tel) {
        this.$message.error('请填写电话');
        this.isDisabled = false;
        return;
      }
      if (!this.dataForm.content) {
        this.$message.error('请填写留言');
        this.isDisabled = false;
        return;
      }
      const reqForm = { ...this.dataForm };
      const [err, res] = await this.$util.to(this.$api.IndexMessages_addIndexMessages(reqForm));
      this.resetForm();
      this.isDisabled = false;
      if (res) {
        this.$message.success('提交成功！');
      }
    },
    resetForm() {
      this.dataForm = {
        tel: '',
        linkman: '',
        content: '',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.disabled {
  background-color: rgba($color: #fff, $alpha: 0.4) !important;
  color: rgba($color: #000000, $alpha: 0.2) !important;
  cursor: default !important;
}
.clo-send-message--wrapper {
  @include flex-row(center);
  margin-bottom: -100px;
  width: 100%;
  height: 200px;
  position: relative;
  z-index: 99;
  .clo-send-message {
    @include flex-column(space-between,flex-start);
    width: 1200px;
    height: 100%;
    background-color: #F39800;
    padding: 40px;
    box-sizing: border-box;
    &__title {
      &--text {
        @include font-init(24px,#fff,700);
      }
      &--line {
        width: 40px;
        height: 4px;
        background-color: #fff;
        margin-top: 30px;
      }
    }
    &__form {
      @include flex-row(space-between, flex-end);
      width: 100%;
      &--item {
        .item__input {
          width: 300px;
          height: 30px;
          background-color: #F39800;
          border: 0;
          border-bottom: 1px solid #fff;
          outline: none;
          color: #fff;
          &::placeholder {
            @include font-init(16px, #fff,500);
          }
        }
      }
      &--submit {
        @include font-init(24px, #222222, 700);
        padding: 19px 31px;
        background-color: #fff;
        cursor: pointer;
      }
    }
  }
}
</style>
