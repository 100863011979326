var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "clo-about-us__navigation--wrapper" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "clo-about-us__list" },
      _vm._l(_vm.navigationList, function(item, index) {
        return _c(
          "div",
          { key: index, staticClass: "clo-about-us__list--item" },
          [
            _c("div", { staticClass: "item__icon" }, [
              _c("img", {
                directives: [{ name: "imageError", rawName: "v-imageError" }],
                staticClass: "item__image",
                attrs: { src: item.icon, alt: "" }
              })
            ]),
            _c("div", { staticClass: "item__text" }, [
              _vm._v(_vm._s(item.text))
            ])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "clo-about-us__navigation" }, [
      _c("div", { staticClass: "clo-about-us__navigation--title" }, [
        _c("div", { staticClass: "title__english" }, [
          _vm._v(" Choose"),
          _c("span", [_vm._v("us")])
        ]),
        _c("div", { staticClass: "title__subtitle" }, [
          _vm._v("因为我们专业｜所以选择我们")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }