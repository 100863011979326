var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "clo-about-us--wrapper" }, [
    _c("div", { staticClass: "clo-about-us" }, [
      _c(
        "div",
        { staticClass: "clo-about-us__content" },
        [
          _c("div", { staticClass: "clo-about-us__shadow" }),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 14 } }, [
                _c("div", { staticClass: "clo-about-us__content--left" }, [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c("div", { staticClass: "left__title" }, [
                      _c("div", { staticClass: "left__title--english" }, [
                        _vm._v(" ABOUT"),
                        _c("span", [_vm._v("US")])
                      ]),
                      _c("div", { staticClass: "left__title--subtitle" }, [
                        _vm._v("关 | 于 | 我 | 们")
                      ]),
                      _c("div", { staticClass: "left__title--line" })
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "left__content",
                        staticStyle: { "margin-left": "120px" }
                      },
                      [
                        _c("div", [_vm._v("我们可以帮您实现......")]),
                        _c("div", [_vm._v("1.老板的遥控指挥")]),
                        _c("div", [_vm._v("2.商品的销量分析")]),
                        _c("div", [_vm._v("3.业务员的盈利对比")]),
                        _c("div", [_vm._v("4.客户的销量统计")]),
                        _c("div", [_vm._v("5.往来账的实时监控")]),
                        _c("div", [_vm._v("6.促销政策的智能管控")]),
                        _c("div", [_vm._v("7.费用的精准把握")]),
                        _c("div", [_vm._v("8.现场打印和订单无纸化分享")]),
                        _c("div", [_vm._v("9.工资的实时核算")]),
                        _c("div", [_vm._v("10.智能的价格管理体系")])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "left__content" }, [
                    _c(
                      "div",
                      {
                        staticClass: "pos-re",
                        staticStyle: { "margin-top": "25px" }
                      },
                      [
                        _c("div", { staticClass: "abs-dot grey-dot" }),
                        _c("div", [
                          _vm._v(
                            "客户的位置、门头照片、欠款上限、一店一品一价、导航集于一身"
                          )
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "pos-re" }, [
                      _c("div", { staticClass: "abs-dot grey-dot" }),
                      _c("div", [
                        _vm._v(
                          "仓库商品的数量价值、临期、过期、生产日期图片的实时显示和杏询"
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "pos-re" }, [
                      _c("div", { staticClass: "abs-dot grey-dot" }),
                      _c("div", [
                        _vm._v(
                          "业务员的考勒工资拜访明细、实时路线、销量分析一键查询"
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "pos-re" }, [
                      _c("div", { staticClass: "abs-dot grey-dot" }),
                      _c("div", [
                        _vm._v(
                          "财务数据的精准分析、多维度的查看、准确的报表，帮您做准确的判断"
                        )
                      ])
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "left__number",
                      staticStyle: { "margin-top": "20px" }
                    },
                    [
                      _c("div", { staticClass: "left__number--content" }, [
                        _c("img", {
                          staticClass: "icon__image",
                          attrs: { src: _vm.Number01, alt: "" }
                        })
                      ]),
                      _c("div", { staticClass: "left__number--line" })
                    ]
                  )
                ])
              ]),
              _c("el-col", { attrs: { span: 10 } }, [
                _c("div", { staticClass: "clo-about-us__content--right" }, [
                  _c("img", {
                    directives: [
                      { name: "imageError", rawName: "v-imageError" }
                    ],
                    staticClass: "right__iamge",
                    attrs: { src: _vm.AboutUs, alt: "" }
                  })
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "clo-about-us__box--bottom" }, [
        _c("div", { staticClass: "bottom__content" }, [
          _c("img", {
            staticClass: "icon__image",
            attrs: { src: _vm.Number02, alt: "" }
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }