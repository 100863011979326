<template>
  <div class="clo-instruction--wrapper">
    <div class="clo-instruction">
      <el-row>
        <el-col :span="10" :offset="1">
          <div class="clo-instruction--left">
            <div class="left__title">
              <div class="left__title--subtitle">联系我们</div>
              <div class="left__title--line"></div>
            </div>
            <div class="left__address">
              <div class="left__address--title">地址：</div>
              <div class="left__address--text">浙江·台州云销宝科技有限公司</div>
              <div class="left__address--text">浙江·台州市黄岩区科新路25号</div>
            </div>
            <div class="left__network">
              <div class="left__network--title">网址：</div>
              <div class="left__network--text">www.yxbabc.com</div>
            </div>
            <div class="left__phone-service">
              <div class="left__phone-service--title">客服电话：</div>
              <div class="left__phone-service--text">0576-89881088</div>
            </div>
            <div class="left__phone-buiness">
              <div class="left__phone-buiness--title">业务电话：</div>
              <div class="left__phone-buiness--text">15653337788</div>
            </div>
          </div>
        </el-col>
        <el-col :span="13">
          <div class="clo-instruction--right">
            <div class="right__title">
              <div class="right__title--subtitle">手机下载</div>
              <div class="right__title--line"></div>
            </div>
            <div class="right__list">
              <div class="right__list--item" v-for="(item, index) in iconList" :key="index">
                <div class="item__icon">
                  <img class="item__image" :src="item.icon" alt="" v-imageError>
                </div>
                <div class="item__text">{{item.text}}</div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="clo-instruction__line"></div>
      <div class="clo-instruction--footer">
        <span>© 2018-2019 yxbabc.com  版权所有  ICP证：</span>
        <!-- <a style="color: #fff;" href="http://www.beian.miit.gov.cn" target="_blank">浙ICP备18040182号-1</a> -->
        <a style="color: #fff;" href="https://beian.miit.gov.cn/" target="_blank">浙ICP备18040182号-1</a>
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
import Code1 from '@/assets/index/code1@2x.png';
import Code2 from '@/assets/index/code2@2x.png';
import Code3 from '@/assets/index/code3@2x.png';

export default {
  data() {
    return {
      iconList: [
        {
          icon: Code1,
          text: '二维码',
        },
        {
          icon: Code2,
          text: '安卓手机下载',
        },
        {
          icon: Code3,
          text: '苹果iOS下载',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo-instruction--wrapper {
  @include flex-row(center);
  width: 100%;
  // height: 586px;
  background-color: #222222;
  .clo-instruction {
    width: 1200px;
    height: 100%;
    padding: 190px 0 40px 0;
    box-sizing: border-box;
    &--left {
      @include flex-column(space-around, flex-start);
      height: 315px;
      .left__title {
        &--subtitle {
          @include font-init(24px,#fff,700);
          text-align: left;
        }
        &--line {
          width: 40px;
          height: 4px;
          background-color: #F39800;
          margin-top: 20px;
        }
      }
      .left__address {
        @include font-init(16px,#fff,500);
        &--title {
          text-align: left;
        }
        &--text {
          margin-top: 4px;
        }
      }
      .left__network {
        @include font-init(16px,#fff,500);
        &--title {
          text-align: left;
        }
        &--text {
          margin-top: 4px;
        }
      }
      .left__phone-service {
        @include font-init(16px,#fff,500);
        &--title {
          text-align: left;
        }
        &--text {
          margin-top: 4px;
        }
      }
      .left__phone-buiness {
        @include font-init(16px,#fff,500);
        &--title {
          text-align: left;
        }
        &--text {
          margin-top: 4px;
        }
      }
    }
    &--right {
      @include flex-column(space-around, flex-start);
      height: 315px;
      .right__title {
        &--subtitle {
          @include font-init(24px,#fff,700);
          text-align: left;
        }
        &--line {
          width: 40px;
          height: 4px;
          background-color: #F39800;
          margin-top: 20px;
        }
      }
      .right__list {
        @include flex-row(space-between);
        height: 215px;
        &--item {
          @include flex-column(space-between);
          margin-right: 30px;
          height: 100%;
          .item__icon {
            width: 180px;
            height: 180px;
            .item__image {
              width: 100%;
              height: 100%;
            }
          }
          .item__text {
            @include font-init(20px,#fff,500);
            color: #fff;
          }
        }
      }
    }
    &__line {
      border-bottom: 1px solid #aeb6bc;
      margin-top: 40px;
    }
    &--footer {
      margin-top: 70px;
      text-align: center;
      color: #757789;
    }
  }
}
</style>
