<template>
  <div class="clo-company-history--wrapper">
    <div class="clo-company-history">
      <div class="clo-company-history__title">
        <div class="clo-company-history__title--english">
          COMPANY<span>HISTORY</span>
        </div>
        <div class="clo-company-history__title--subtitle">
          <div class="subtitle__line"></div>
          <div class="subtitle__text">公 | 司 | 历 | 程 | 与 | 展 | 示</div>
          <div class="subtitle__line"></div>
          </div>
      </div>
      <div class="clo-company-history__content">
        <div class="clo-company-history__shadow"></div>
        <el-row>
          <el-col :span="12">
            <div class="clo-company-history__content--left">
              <div class="left__number">
                <div class="left__number--content">
                  <img class="icon__image" :src="dataObj.index" alt="">
                </div>
                <div class="left__number--line"></div>
              </div>
              <div class="left__title">
                <div class="left__title--subtitle">{{dataObj.title}}</div>
              </div>
              <div class="left__content">
                <div style="text-indent:2em;height:140px;line-height:1.3em">{{dataObj.desc}}</div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="clo-company-history__content--right">
              <img class="right__iamge" :src="dataObj.img" alt="" v-imageError>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="clo-company-history-list">
      <div class="clo-company-history-list__image-list">
        <div
          class="clo-company-history-list__item"
          @click="resetActive(index)"
          v-for="(item,index) in historyList"
          :key="index"
        >
          <img class="item__image" :src="item.img" alt="">
          <div class="item__shadow" v-if="active!==index"></div>
          <div class="item__title" v-if="active!==index">{{item.title}}</div>
        </div>
      </div>
      <div class="clo-company-history-list__title-list">
        <div
          class="barItem"
          :class="{select: active===index }"
          v-for="(item,index) in historyList"
          :key="index"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import Number01 from '@/assets/index/01@2x.png';
import Number02 from '@/assets/index/02@2x.png';
import Number03 from '@/assets/index/03@2x.png';
import Number04 from '@/assets/index/04@2x.png';
import Number05 from '@/assets/index/05@2x.png';
import History from '@/assets/index/history.jpg';
import History1 from '@/assets/index/history1.jpg';
import History2 from '@/assets/index/history2.jpg';
import History3 from '@/assets/index/history3.jpg';
import History4 from '@/assets/index/history4.jpg';

export default {
  data() {
    return {
      Number04,
      historyList: [
        {
          index: Number01,
          img: History,
          title: '历代版本',
          desc: '货郎日记APP从1.0版本到现在的6.0版本的改变，都是各位经销商给我们提议，我们结合经销商的需求再每一个版本的不断改变中成长的！',
        },
        {
          index: Number02,
          img: History1,
          title: '细心的客服团队',
          desc: '我们拥有专业的售后服务团队，提供专人一对一的终生售后服务，品质是一个企业的生命，服务是一个企业的寿命，专业指导软件的使用，我们定期电话回访服务跟进，服务没有起点，满意没有终点，您的满意是我们永远的追求',
        },
        {
          index: Number03,
          img: History2,
          title: '展会中的’娘子军’',
          desc: '细心、耐心，服务我们是认真的，努力就是我们的宗旨！细心服务客户就是我们的努力目标！秉承“超越客户期望值的服务”为经营理念.微笑在脸，服务在于心。虽然我们是娘子军，我们的软件知识一点也不比他们差！',
        },
        {
          index: Number04,
          img: History3,
          title: '细心的地推服务',
          desc: '主动、用心的服务才能给客户留下美好印象。甚至会大热天，蹲着给客户讲解一个多小时。坚持“成交不是终点，服务从心开始”的服务理念，让每位客户在`云销宝·货郎日记`都能享受到宾至如归的亲切感。',
        },
        {
          index: Number05,
          img: History4,
          title: '经销商的集体培训',
          desc: '做培训，我们是认真的，从来不马虎。真正解决客户意向不足的难题，更详细具体了解我们软件的好处与作用。让每一个经销商都用上好用的帮手‘云销宝•货郎日记’',
        },
      ],
      active: 0,
      timer: null,
    };
  },
  created() {
    [this.dataObj] = this.historyList;
  },
  mounted() {
    this.switchHistoryList();
  },
  methods: {
    switchHistoryList() {
      this.timer = setInterval(() => {
        if (this.active >= this.historyList.length - 1) {
          this.active = 0;
        } else {
          this.active += 1;
        }
        this.showMapInfo();
      }, 4000);
    },
    resetActive(num) {
      clearInterval(this.timer);
      this.active = num;
      this.showMapInfo();
      this.switchHistoryList();
    },
    showMapInfo() {
      this.dataObj = this.historyList[this.active];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo-company-history--wrapper {
  margin: 150px 0 80px 0;
  width: 100%;
  .clo-company-history {
    margin: 0 auto;
    width: 100%;
    height: 675px;
    &__title {
      &--english {
        @include font-init(53px,#333333,800);
        span {
          @include font-init(53px,#6B6B6B,400);
          margin-left: 8px;
        }
      }
      &--subtitle {
        @include font-init(24px,#333333,400);
        @include flex-row(center);
        text-align: left;
        margin-top: 30px;
        .subtitle__line {
          width: 20px;
          height: 2px;
          background-color: #333;
        }
        .subtitle__text {
          padding: 0 25px;
        }
      }
    }
    &__content {
      width: 100%;
      height: 500px;
      position: relative;
      margin-top: 80px;
      .clo-about-us__shadow {
        width: 317px;
        height: 500px;
        background-color: rgba($color: #000000, $alpha: 0.2);
        position: absolute;
        top: 0;
        right: 500px;
      }
      &--left {
        @include flex-column(space-between,flex-start);
        height: 500px;
        padding-left: 160px;
        box-sizing: border-box;
        background-color: #EEEEEE;
        .left__number {
          margin-top: 85px;
          &--content {
            @include font-init(70px,#333,$ff:'Impact Regular, Impact Regular-Regular;');
            text-align: left;
            width: 70px;
            height: 59px;
            &--weight {
              font-family: PingFang SC, PingFang SC-Medium;
              font-weight: 500;
              font-size: 35px;
            }
            .icon__image {
              width: 100%;
              height: 100%;
            }
          }
          &--line {
            width: 100px;
            height: 2px;
            background: #333;
            margin-top: 19px;
          }
        }
        .left__title {
          &--english {
            @include font-init(24px,#333333,800);
            text-align: left;
          }
          &--subtitle {
            @include font-init(24px,#333333,800);
            text-align: left;
            // margin-top: 18px;
          }
          &--line {
            width: 60px;
            height: 2px;
            background-color: #333;
            margin-top: 30px;
          }
        }
        .left__content {
          @include font-init(18px,#6B6B6B,500);
          text-align: left;
          margin-bottom: 100px;
          width: 540px;
          div {
            margin-top: 7px;
          }
        }
      }
      &--right {
        width: 100%;
        height: 500px;
        .right__iamge {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .clo-company-history-list {
    width: 100%;
    height: 200px;
    &__image-list {
      @include flex-row();
      width: 100%;
      .clo-company-history-list__item {
        width: 384px;
        height: 200px;
        position: relative;
        cursor: pointer;
        .item__image {
          width: 100%;
          height: 100%;
        }
        .item__shadow {
          position: absolute;
          top: 0;
          left: 0;
          width: 384px;
          height: 200px;
          background-color: rgba($color: #000000, $alpha: 0.6);
        }
        .item__title {
          @include flex-row(center);
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 9;
          color: #fff;
        }
      }
    }
    &__title-list {
      @include flex-row(space-between);
      background-color: #fff;
      width: 400px;
      padding: 20px;
      margin: 0 auto;
      .barItem {
        width: 50px;
        height: 6px;
        border-radius: 3px;
        background-color: #E3E3E3;
        &.select {
          background-color: #FA8F12;
        }
      }
    }
  }
}
</style>
