<template>
  <div class="clo-product-app--wrapper">
    <div class="clo-product-app">
      <div class="clo-product-app__content">
        <el-row>
          <el-col :span="12">
            <div class="clo-product-app__content--left">
              <div class="left__title">
                <div class="left__title--subtitle">厂 | 商 | 端</div>
              </div>
              <div class="left__content">
                <div>1.及时掌握经销商的库存和销售情况，不能简单粗暴的压货。</div>
                <div>2.积极调整销售策略，开发更多能让你的合作伙伴和员工赚钱的产品</div>
                <div>和方式。</div>
                <div>3.了解你的经销商的财务状况，不要只是对上预存款，对下是赊欠+退</div>
                <div>货+死账+垫付费用+营销所产生的各种费用，最终把经销商压死了。</div>
                <div>4.不要让经销商给你虚假的数据，我们要真实的汇总数据来判断我们</div>
                <div>的产品走向。</div>
              </div>
              <div class="left__detail">
                <div class="left__detail-content">查看详情</div>
                <div class="left__detail-icon">
                  <img class="left__detail-icon-img" :src="ArrowsRight" alt="">
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="clo-product-app__content--right">
              <img class="right__iamge" :src="Manufacturer" alt="" v-imageError>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowsRight from '@/assets/common/arrows-right.png';
import Manufacturer from '@/assets/exhibition/manufacturer.png';

export default {
  data() {
    return {
      ArrowsRight,
      Manufacturer,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo-product-app--wrapper {
  @include flex-row(center);
  margin: 150px 0 80px 0;
  width: 100%;
  // height: 600px;
  position: relative;
  .clo-product-app {
    width: 1200px;
    // height: 100%;
    &__content {
      width: 100%;
      // height: 500px;
      position: relative;
      &--left {
        @include flex-column(space-between,flex-start);
        height: 370px;
        .left__title {
          &--subtitle {
            @include font-init(23px,#333333,800);
            text-align: left;
          }
        }
        .left__content {
          @include font-init(18px,#6B6B6B,500);
          margin-bottom: 60px;
          text-align: left;
          div {
            margin-top: 7px;
          }
        }
        .left__detail {
          @include flex-row(center);
          width: 170px;
          height: 40px;
          border: 1px solid #535353;
          &-content {
            @include font-init(20px,#000,500);
            @include flex-row(center);
            width: 130px;
            height: 100%;
            border-right: 1px solid #000
          }
          &-icon {
            flex-grow: 1;
            &-img {
              width: 25px;
            }
          }
        }
      }
      &--right {
        @include flex-row();
        width: 566px;
        height: 452px;
        .right__iamge {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
