<template>
  <div class="clo" ref="clo" @scroll="scrollEvent">
    <div class="clo-header">
      <!-- sub-top -->
      <clo-sub-top></clo-sub-top>
      <!-- top -->
      <clo-top ref="top" :headerFixed="headerFixed" :actived.sync="cloTopActived"></clo-top>
      <div v-if="headerFixed" style="height:110px"></div>
      <!-- 轮播图 -->
      <el-carousel height="650px">
        <el-carousel-item v-for="item in bannerList" :key="item">
          <img class="clo-header__switch--iamge" :src="item" alt="" v-imageError>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="clo-body">
      <!-- 01 -->
      <clo-about-us></clo-about-us>
      <!-- 02 -->
      <clo-about-us-navigation></clo-about-us-navigation>
      <!-- 03 -->
      <div class="clo__box--bottom">
        <div class="bottom__content">
          <div class="bottom__content--icon">
            <img class="icon__image" :src="Number03" alt="">
          </div>
          <div class="bottom__content--english">OUR</div>
          <div class="bottom__content--english bottom__content--english-thin">PRODUCTS</div>
          <div class="bottom__content--subtitle">我｜们｜的｜产｜品</div>
        </div>
      </div>
      <product-app></product-app>
      <product-web></product-web>
      <product-staff></product-staff>
      <product-manufacturer></product-manufacturer>
      <product-little-program></product-little-program>
      <!-- 04 -->
      <clo-company-history></clo-company-history>
    </div>
    <div class="clo--footer">
      <clo-send-message></clo-send-message>
      <clo-instruction></clo-instruction>
    </div>
    <div class="service" @click="showServiceImage">
      <img class="service__image" :src="Service" alt="">
    </div>
    <div class="company" @click="indexGoEntrance(0)">
      <img class="company__image" :src="Company" alt="">
      <div class="company__text">生产商登录</div>
    </div>
    <div class="merchant" @click="indexGoEntrance(1)">
      <img class="merchant__image" :src="Merchant" alt="">
      <div class="merchant__text">经销商登录</div>
    </div>

    <clo-dialog ref="serviceImageDialog" width="500px" hidebtns>
      <div class="dialog-content">
        <img class="serviceImageDialog__image" :src="wechatService001" alt="">
      </div>
    </clo-dialog>

    <el-backtop target=".clo">
      <img class="el-backtop__image" :src="BackTop" alt="">
    </el-backtop>
  </div>
</template>

<script>
import Service from '@/assets/index/service.png';
import Company from '@/assets/index/company.png';
import Merchant from '@/assets/index/merchant.png';
import wechatService001 from '@/assets/index/wechat-service001.jpg';
import BackTop from '@/assets/index/back-top.png';

import Banner from '@/assets/index/banner@2x.jpg';
import Banner1 from '@/assets/index/banner1@2x.jpg';
import Banner2 from '@/assets/index/banner2@2x.jpg';
import Number03 from '@/assets/index/03@2x.png';

import Util from '@/util/util';
import CloSubTop from './components/sub-top.vue';
import CloTop from './components/top.vue';
import CloAboutUs from './components/about-us.vue';
import CloAboutUsNavigation from './components/about-us-navigation.vue';
import CloCompanyHistory from './components/company-history.vue';
import CloSendMessage from './components/send-message.vue';
import CloInstruction from './components/instruction.vue';
import ProductApp from './components/product-app.vue';
import ProductStaff from './components/product-staff.vue';
import productLittleProgram from './components/product-little-program.vue';
import ProductWeb from './components/product-web.vue';
import ProductManufacturer from './components/product-manufacturer.vue';

export default {
  components: {
    CloSubTop,
    CloTop,
    CloAboutUs,
    CloAboutUsNavigation,
    CloCompanyHistory,
    CloSendMessage,
    CloInstruction,
    ProductApp,
    ProductStaff,
    productLittleProgram,
    ProductWeb,
    ProductManufacturer,
  },
  data() {
    return {
      Service,
      Company,
      Merchant,
      wechatService001,
      BackTop,
      bannerList: [
        Banner,
        Banner1,
        Banner2,
      ],
      Number03,
      offsetTop: 0,
      offsetHeight: 0,
      headerFixed: false,
      cloTopActived: '',
    };
  },
  mounted() {
    this.$nextTick(() => {
      // 获取 top 的 dom
      const header = this.$refs.top.$refs.cloTop;
      this.offsetTop = header.offsetTop;
      this.offsetHeight = header.offsetHeight;
    });
    const isMobile = Util.judgeService();
    if (isMobile) this.$router.push('/mobile');
  },
  methods: {
    showServiceImage() {
      this.$refs.serviceImageDialog.show(
        '客服微信二维码',
        null,
        () => {
          this.$refs.serviceImageDialog.hide();
        },
        () => {
          this.$refs.serviceImageDialog.hide();
        },
      );
    },
    indexGoEntrance(num) {
      const obj = {
        0: 'https://web.tzyxb.com/webfactory/#/',
        1: 'https://web.tzyxb.com/webboss/#/',
      };
      window.open(obj[num]);
    },
    updataCloTopActived(num) {
      this.cloTopActived = num;
    },
    scrollEvent() {
      const { clo } = this.$refs;
      const { scrollTop } = clo;

      this.headerFixed = scrollTop > (this.offsetTop);
      if (scrollTop > 699 && scrollTop < 2079) {
        this.cloTopActived = 0;
      } else if (scrollTop > 2079 && scrollTop < 5683) {
        this.cloTopActived = 1;
      } else if (scrollTop > 5683 && scrollTop < 6520) {
        this.cloTopActived = 2;
      } else if (scrollTop > 6520 && scrollTop < 6735) {
        this.cloTopActived = 3;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  .clo__box--bottom {
    width: 1200px;
    height: 150px;
    border-left: 2px solid #333;
    margin: 0 auto;
    .bottom__content {
      @include flex-row(flex-start, flex-end);
      height: 100%;
      &--icon {
        width: 70px;
        height: 59px;
        margin-left: 19px;
        .icon__image {
          width: 100%;
          height: 100%;
        }
      }
      &--english {
        @include font-init(53px,#333333,800);
        margin-left: 30px;
        &.bottom__content--english-thin {
          @include font-init(53px,#999,400);
        }
        span {
          @include font-init(53px,#6B6B6B,300);
          margin-left: 8px;
        }
      }
      &--subtitle {
        @include font-init(24px,#333333,800);
        margin-left: 29px;
        margin-bottom: 7px;
        text-align: left;
      }
    }
  }
  &-header {
    ::v-deep .el-carousel__indicator {
      width: 60px;
      height: 8px;
      &.is-active {
        .el-carousel__button {
          width: 60px;
          height: 8px;
          border-radius: 4px;
          background-color: #FA8E19;
        }
      }
      .el-carousel__button {
        width: 60px;
        height: 8px;
        border-radius: 4px;
        background-color: #E2E2E2;
      }
    }
  }
  .clo-header__switch--iamge {
    width: 100%;
    height: 650px;
    object-fit: cover
  }
  .service {
    width: 100px;
    height: 100px;
    position: fixed;
    right: 18px;
    bottom: 604px;
    z-index: 99;
    &__image {
      width: 100%;
      height: 100%;
    }
  }
  .company {
    width: 100px;
    height: 100px;
    position: fixed;
    right: 18px;
    bottom: 484px;
    z-index: 99;
    &__image {
      width: 100%;
      height: 100%;
    }
    &__text {
      @include flex-row(center);
      width: 100%;
      position: absolute;
      bottom: 3px;
      color: #fff;
      font-size: 12px;
    }
  }
  .merchant {
    width: 100px;
    height: 100px;
    position: fixed;
    right: 18px;
    bottom: 364px;
    z-index: 99;
    &__image {
      width: 100%;
      height: 100%;
    }
    &__text {
      @include flex-row(center);
      width: 100%;
      position: absolute;
      bottom: 3px;
      color: #fff;
      font-size: 12px;
    }
  }
  .serviceImageDialog__image {
    width: 337px;
    height: 448px;
  }
  ::v-deep .el-backtop {
    right: 18px !important;
    bottom: 240px !important;
    width: 100px;
    height: 100px;
  }
}
</style>
