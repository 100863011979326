var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "clo", staticClass: "clo", on: { scroll: _vm.scrollEvent } },
    [
      _c(
        "div",
        { staticClass: "clo-header" },
        [
          _c("clo-sub-top"),
          _c("clo-top", {
            ref: "top",
            attrs: { headerFixed: _vm.headerFixed, actived: _vm.cloTopActived },
            on: {
              "update:actived": function($event) {
                _vm.cloTopActived = $event
              }
            }
          }),
          _vm.headerFixed
            ? _c("div", { staticStyle: { height: "110px" } })
            : _vm._e(),
          _c(
            "el-carousel",
            { attrs: { height: "650px" } },
            _vm._l(_vm.bannerList, function(item) {
              return _c("el-carousel-item", { key: item }, [
                _c("img", {
                  directives: [{ name: "imageError", rawName: "v-imageError" }],
                  staticClass: "clo-header__switch--iamge",
                  attrs: { src: item, alt: "" }
                })
              ])
            }),
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "clo-body" },
        [
          _c("clo-about-us"),
          _c("clo-about-us-navigation"),
          _c("div", { staticClass: "clo__box--bottom" }, [
            _c("div", { staticClass: "bottom__content" }, [
              _c("div", { staticClass: "bottom__content--icon" }, [
                _c("img", {
                  staticClass: "icon__image",
                  attrs: { src: _vm.Number03, alt: "" }
                })
              ]),
              _c("div", { staticClass: "bottom__content--english" }, [
                _vm._v("OUR")
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "bottom__content--english bottom__content--english-thin"
                },
                [_vm._v("PRODUCTS")]
              ),
              _c("div", { staticClass: "bottom__content--subtitle" }, [
                _vm._v("我｜们｜的｜产｜品")
              ])
            ])
          ]),
          _c("product-app"),
          _c("product-web"),
          _c("product-staff"),
          _c("product-manufacturer"),
          _c("product-little-program"),
          _c("clo-company-history")
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "clo--footer" },
        [_c("clo-send-message"), _c("clo-instruction")],
        1
      ),
      _c(
        "div",
        { staticClass: "service", on: { click: _vm.showServiceImage } },
        [
          _c("img", {
            staticClass: "service__image",
            attrs: { src: _vm.Service, alt: "" }
          })
        ]
      ),
      _c(
        "div",
        {
          staticClass: "company",
          on: {
            click: function($event) {
              return _vm.indexGoEntrance(0)
            }
          }
        },
        [
          _c("img", {
            staticClass: "company__image",
            attrs: { src: _vm.Company, alt: "" }
          }),
          _c("div", { staticClass: "company__text" }, [_vm._v("生产商登录")])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "merchant",
          on: {
            click: function($event) {
              return _vm.indexGoEntrance(1)
            }
          }
        },
        [
          _c("img", {
            staticClass: "merchant__image",
            attrs: { src: _vm.Merchant, alt: "" }
          }),
          _c("div", { staticClass: "merchant__text" }, [_vm._v("经销商登录")])
        ]
      ),
      _c(
        "clo-dialog",
        { ref: "serviceImageDialog", attrs: { width: "500px", hidebtns: "" } },
        [
          _c("div", { staticClass: "dialog-content" }, [
            _c("img", {
              staticClass: "serviceImageDialog__image",
              attrs: { src: _vm.wechatService001, alt: "" }
            })
          ])
        ]
      ),
      _c("el-backtop", { attrs: { target: ".clo" } }, [
        _c("img", {
          staticClass: "el-backtop__image",
          attrs: { src: _vm.BackTop, alt: "" }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }