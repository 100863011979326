var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "cloTop",
      staticClass: "clo-top--wrapper",
      class: { headerFixed: _vm.headerFixed }
    },
    [
      _c(
        "div",
        { staticClass: "clo-top" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 4 } }, [
                _c("div", { staticClass: "clo-top__logo" }, [
                  _c("img", {
                    staticClass: "logo__image",
                    attrs: { src: _vm.Logo, alt: "" }
                  })
                ])
              ]),
              _c("el-col", { attrs: { span: 12, offset: 8 } }, [
                _c("div", { staticClass: "clo-top__bar" }, [
                  _c(
                    "div",
                    {
                      staticClass: "clo-top__bar-item ",
                      class: { "clo-top__bar-item--active": _vm.actived === 0 },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.goModelPage(0)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "item__title" }, [
                        _vm._v("关于我的")
                      ]),
                      _c("div", { staticClass: "item__subtitle" }, [
                        _vm._v("About")
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "clo-top__bar-item",
                      class: { "clo-top__bar-item--active": _vm.actived === 1 },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.goModelPage(1)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "item__title" }, [
                        _vm._v("产品功能")
                      ]),
                      _c("div", { staticClass: "item__subtitle" }, [
                        _vm._v("features")
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "clo-top__bar-item",
                      class: { "clo-top__bar-item--active": _vm.actived === 2 },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.goModelPage(2)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "item__title" }, [
                        _vm._v("公司历程")
                      ]),
                      _c("div", { staticClass: "item__subtitle" }, [
                        _vm._v("history")
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "clo-top__bar-item",
                      class: { "clo-top__bar-item--active": _vm.actived === 3 },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.goModelPage(3)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "item__title" }, [
                        _vm._v("联系我们")
                      ]),
                      _c("div", { staticClass: "item__subtitle" }, [
                        _vm._v("Contact")
                      ])
                    ]
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }