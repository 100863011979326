var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "clo-product-app--wrapper" }, [
    _c("div", { staticClass: "clo-product-app" }, [
      _c(
        "div",
        { staticClass: "clo-product-app__content" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "clo-product-app__content--left" }, [
                  _c("div", { staticClass: "left__title" }, [
                    _c("div", { staticClass: "left__title--subtitle" }, [
                      _vm._v("web | 老 | 板 | 端")
                    ])
                  ]),
                  _c("div", { staticClass: "left__content" }, [
                    _c("div", [
                      _vm._v(
                        "1.报表查询：库存数量，库存金额，商品毛利，包括业务和客户销"
                      )
                    ]),
                    _c("div", [_vm._v("售金额。")]),
                    _c("div", [
                      _vm._v(
                        "2.数据分析：帮老板有效分析单品利润排行、分析客户销售数据、"
                      )
                    ]),
                    _c("div", [
                      _vm._v(
                        "分析业务员销售情况，使得公司在大环境不好的状况下，营业额利"
                      )
                    ]),
                    _c("div", [_vm._v("润逆势而上!")]),
                    _c("div", [_vm._v("3.库存管理")]),
                    _c("div", [
                      _vm._v(
                        "①.查看入库记录②.盘点记录和出库记录做到多库房管 理，库管扫"
                      )
                    ]),
                    _c("div", [_vm._v("码入库。")]),
                    _c("div", [
                      _vm._v(
                        "4.预付款/欠款管理：根据客户名称，轻松输入预付款/欠款金额"
                      )
                    ]),
                    _c("div", [_vm._v("，并可查看到变更明细。")])
                  ]),
                  _c("div", { staticClass: "left__detail" }, [
                    _c("div", { staticClass: "left__detail-content" }, [
                      _vm._v("查看详情")
                    ]),
                    _c("div", { staticClass: "left__detail-icon" }, [
                      _c("img", {
                        staticClass: "left__detail-icon-img",
                        attrs: { src: _vm.ArrowsRight, alt: "" }
                      })
                    ])
                  ])
                ])
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "clo-product-app__content--right" }, [
                  _c("img", {
                    directives: [
                      { name: "imageError", rawName: "v-imageError" }
                    ],
                    staticClass: "right__iamge",
                    attrs: { src: _vm.boss, alt: "" }
                  })
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }