<template>
  <div class="clo-about-us__navigation--wrapper">
    <div class="clo-about-us__navigation">
      <div class="clo-about-us__navigation--title">
        <div class="title__english">
          Choose<span>us</span>
        </div>
        <div class="title__subtitle">因为我们专业｜所以选择我们</div>
      </div>
    </div>
    <div class="clo-about-us__list">
      <div class="clo-about-us__list--item" v-for="(item, index) in navigationList" :key="index">
        <div class="item__icon">
          <img class="item__image" :src="item.icon" alt="" v-imageError>
        </div>
        <div class="item__text">{{item.text}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import cangkuguanli from '@/assets/index/cangkuguanli@2x.png';
import changjiaguanli from '@/assets/index/changjiaguanli@2x.png';
import feiyongguanli from '@/assets/index/feiyongguanli@2x.png';
import kehuguanli from '@/assets/index/kehuguanli@2x.png';
import shangpinguanli from '@/assets/index/shangpinguanli@2x.png';
import yewuyuanguanli from '@/assets/index/yewuyuanguanli@2x.png';

export default {
  data() {
    return {
      navigationList: [
        {
          icon: changjiaguanli,
          text: '厂家管理',
        },
        {
          icon: kehuguanli,
          text: '客户管理',
        },
        {
          icon: shangpinguanli,
          text: '商品管理',
        },
        {
          icon: yewuyuanguanli,
          text: '业务员管理',
        },
        {
          icon: cangkuguanli,
          text: '仓库管理',
        },
        {
          icon: feiyongguanli,
          text: '费用管理',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo-about-us__navigation--wrapper {
  @include flex-column(space-between,center);
  background-image: url('../../../assets/index/background-about-us@2x.jpg');
  background-size: cover;
  width: 100%;
  height: 500px;
  background-color: saddlebrown;
  .clo-about-us__navigation {
    width: 1200px;
    &--title {
      text-align: right;
      .title__english {
        @include font-init(53px,#fff,800);
        margin-top: 49px;
        span {
          @include font-init(53px,#aaa,300);
          margin-left: 8px;
        }
      }
      .title__subtitle {
        @include font-init(24px,#fff,800);
        margin-top: 49px;
        margin-top: 18px;
      }
    }
  }
  .clo-about-us__list {
    @include flex-row(space-between);
    width: 1200px;
    height: 163px;
    margin-bottom: 100px;
    &--item {
    @include flex-column(space-between);
    height: 100%;
      .item__icon {
        width: 100px;
        height: 100px;
        .item__image {
          width: 100%;
          height: 100%;
        }
      }
      .item__text {
        color: #fff;
        font-size: 22px;
      }
    }
  }
}
</style>
