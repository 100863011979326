<template>
  <div class="clo-sub-top--wrapper">
    <div class="clo-sub-top">
      <div class="clo-sub-top__tel">
        <div class="clo-sub-top__tel-business">业务电话：15653337788</div>
        <div class="clo-sub-top__tel-service">客服电话：0576-89881088</div>
      </div>
      <div class="clo-sub-top__entrance">
        <div class="clo-sub-top__entrance-manufacturer" @click="goEntrance(0)">生产商登录</div>
        <div class="clo-sub-top__entrance-company" @click="goEntrance(1)">经销商登录</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goEntrance(num) {
      const obj = {
        0: 'https://web.tzyxb.com/webfactory/#/login',
        1: 'https://web.tzyxb.com/webboss/#/login',
      };
      window.open(obj[num]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo-sub-top--wrapper {
  @include flex-row(center);
  width: 100%;
  height: 49px;
  background-color: #222222;
  .clo-sub-top {
    @include flex-row(space-between);
    @include font-init(19px,#fff,500);
    width: 1200px;
    height: 100%;
    &__tel {
      @include flex-row(flex-start);
      &-service {
        margin-left: 37px;
      }
    }
    &__entrance {
      @include flex-row(flex-end);
      &>div {
        cursor: pointer;
      }
      &-company {
        margin-left: 37px;
      }
    }
  }
}
</style>
