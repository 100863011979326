<template>
  <div class="clo-product-app--wrapper">
    <div class="clo-product-app">
      <div class="clo-product-app__content">
        <el-row>
          <el-col :span="12">
            <div class="clo-product-app__content--left">
              <div class="left__title">
                <div class="left__title--subtitle">web | 老 | 板 | 端</div>
              </div>
              <div class="left__content">
                <div>1.报表查询：库存数量，库存金额，商品毛利，包括业务和客户销</div>
                <div>售金额。</div>
                <div>2.数据分析：帮老板有效分析单品利润排行、分析客户销售数据、</div>
                <div>分析业务员销售情况，使得公司在大环境不好的状况下，营业额利</div>
                <div>润逆势而上!</div>
                <div>3.库存管理</div>
                <div>①.查看入库记录②.盘点记录和出库记录做到多库房管 理，库管扫</div>
                <div>码入库。</div>
                <div>4.预付款/欠款管理：根据客户名称，轻松输入预付款/欠款金额</div>
                <div>，并可查看到变更明细。</div>
              </div>
              <div class="left__detail">
                <div class="left__detail-content">查看详情</div>
                <div class="left__detail-icon">
                  <img class="left__detail-icon-img" :src="ArrowsRight" alt="">
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="clo-product-app__content--right">
              <img class="right__iamge" :src="boss" alt="" v-imageError>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowsRight from '@/assets/common/arrows-right.png';
import boss from '@/assets/exhibition/boss.png';

export default {
  data() {
    return {
      ArrowsRight,
      boss,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo-product-app--wrapper {
  @include flex-row(center);
  margin: 150px 0 80px 0;
  width: 100%;
  // height: 600px;
  position: relative;
  .clo-product-app {
    width: 1200px;
    // height: 100%;
    &__content {
      width: 100%;
      // height: 500px;
      position: relative;
      &--left {
        @include flex-column(space-between,flex-start);
        height: 400px;
        .left__title {
          &--subtitle {
            @include font-init(23px,#333333,800);
            text-align: left;
          }
        }
        .left__content {
          @include font-init(18px,#6B6B6B,500);
          // margin-bottom: 80px;
          text-align: left;
          div {
            margin-top: 7px;
          }
        }
        .left__detail {
          @include flex-row(center);
          width: 170px;
          height: 40px;
          border: 1px solid #535353;
          &-content {
            @include font-init(20px,#000,500);
            @include flex-row(center);
            width: 130px;
            height: 100%;
            border-right: 1px solid #000
          }
          &-icon {
            flex-grow: 1;
            &-img {
              width: 25px;
            }
          }
        }
      }
      &--right {
        @include flex-row();
        width: 566px;
        height: 452px;
        .right__iamge {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
