var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "clo-instruction--wrapper" }, [
    _c(
      "div",
      { staticClass: "clo-instruction" },
      [
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 10, offset: 1 } }, [
              _c("div", { staticClass: "clo-instruction--left" }, [
                _c("div", { staticClass: "left__title" }, [
                  _c("div", { staticClass: "left__title--subtitle" }, [
                    _vm._v("联系我们")
                  ]),
                  _c("div", { staticClass: "left__title--line" })
                ]),
                _c("div", { staticClass: "left__address" }, [
                  _c("div", { staticClass: "left__address--title" }, [
                    _vm._v("地址：")
                  ]),
                  _c("div", { staticClass: "left__address--text" }, [
                    _vm._v("浙江·台州云销宝科技有限公司")
                  ]),
                  _c("div", { staticClass: "left__address--text" }, [
                    _vm._v("浙江·台州市黄岩区科新路25号")
                  ])
                ]),
                _c("div", { staticClass: "left__network" }, [
                  _c("div", { staticClass: "left__network--title" }, [
                    _vm._v("网址：")
                  ]),
                  _c("div", { staticClass: "left__network--text" }, [
                    _vm._v("www.yxbabc.com")
                  ])
                ]),
                _c("div", { staticClass: "left__phone-service" }, [
                  _c("div", { staticClass: "left__phone-service--title" }, [
                    _vm._v("客服电话：")
                  ]),
                  _c("div", { staticClass: "left__phone-service--text" }, [
                    _vm._v("0576-89881088")
                  ])
                ]),
                _c("div", { staticClass: "left__phone-buiness" }, [
                  _c("div", { staticClass: "left__phone-buiness--title" }, [
                    _vm._v("业务电话：")
                  ]),
                  _c("div", { staticClass: "left__phone-buiness--text" }, [
                    _vm._v("15653337788")
                  ])
                ])
              ])
            ]),
            _c("el-col", { attrs: { span: 13 } }, [
              _c("div", { staticClass: "clo-instruction--right" }, [
                _c("div", { staticClass: "right__title" }, [
                  _c("div", { staticClass: "right__title--subtitle" }, [
                    _vm._v("手机下载")
                  ]),
                  _c("div", { staticClass: "right__title--line" })
                ]),
                _c(
                  "div",
                  { staticClass: "right__list" },
                  _vm._l(_vm.iconList, function(item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "right__list--item" },
                      [
                        _c("div", { staticClass: "item__icon" }, [
                          _c("img", {
                            directives: [
                              { name: "imageError", rawName: "v-imageError" }
                            ],
                            staticClass: "item__image",
                            attrs: { src: item.icon, alt: "" }
                          })
                        ]),
                        _c("div", { staticClass: "item__text" }, [
                          _vm._v(_vm._s(item.text))
                        ])
                      ]
                    )
                  }),
                  0
                )
              ])
            ])
          ],
          1
        ),
        _c("div", { staticClass: "clo-instruction__line" }),
        _vm._m(0),
        _c("div")
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "clo-instruction--footer" }, [
      _c("span", [_vm._v("© 2018-2019 yxbabc.com 版权所有 ICP证：")]),
      _c(
        "a",
        {
          staticStyle: { color: "#fff" },
          attrs: { href: "https://beian.miit.gov.cn/", target: "_blank" }
        },
        [_vm._v("浙ICP备18040182号-1")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }