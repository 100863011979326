<template>
  <div class="clo-product-app--wrapper">
    <div class="clo-product-app__bar"></div>
    <div class="clo-product-app">
      <div class="clo-product-app__content">
        <el-row>
          <el-col :span="12">
            <div class="clo-product-app__content--right">
              <img class="right__iamge" :src="LittleProgram1" alt="" v-imageError style="margin-right:20px">
              <img class="right__iamge" :src="LittleProgram2" alt="" v-imageError>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="clo-product-app__content--left">
              <div class="left__title">
                <div class="left__title--subtitle">客 | 户 | 小 | 程 | 序</div>
              </div>
              <div class="left__content">
                <div>把自己的店铺开到微信上，下游客户直接打开微信就可以进</div>
                <div>入店铺下单，这简直就是网购式订货.</div>
                <div>我们可以做的无纸化办公;</div>
                <div>客户的电子单据可以永久的保存,永远不丢失,省去了纸质单</div>
                <div>据的不好储存的问题,你也可以让你的客户扫下面的小程序码</div>
                <div>直接扫码加入。</div>
              </div>
              <div class="left__detail">
                <div class="left__detail-content">查看详情</div>
                <div class="left__detail-icon">
                  <img class="left__detail-icon-img" :src="ArrowsRight" alt="">
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowsRight from '@/assets/common/arrows-right.png';
import LittleProgram1 from '@/assets/exhibition/little-program1.png';
import LittleProgram2 from '@/assets/exhibition/little-program2.png';

export default {
  data() {
    return {
      ArrowsRight,
      LittleProgram1,
      LittleProgram2,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo-product-app--wrapper {
  @include flex-row(center);
  margin: 150px 0 80px 0;
  width: 100%;
  height: 600px;
  position: relative;
  .clo-product-app__bar {
    width: 50%;
    height: 300px;
    background-color: #F39800;
    position: absolute;
    top: 150px;
    left: 0;
  }
  .clo-product-app {
    width: 1200px;
    height: 100%;
    &__content {
      width: 100%;
      height: 500px;
      position: relative;
      &--left {
        @include flex-column(space-between,flex-end);
        height: 600px;
        padding: 70px 0;
        .left__title {
          &--subtitle {
            @include font-init(23px,#333333,800);
            text-align: left;
            margin-top: 18px;
          }
        }
        .left__content {
          @include font-init(18px,#6B6B6B,500);
          // margin-bottom: 100px;
          text-align: left;
          div {
            margin-top: 7px;
            text-align: right;
          }
        }
        .left__detail {
          @include flex-row(center);
          width: 170px;
          height: 40px;
          border: 1px solid #535353;
          margin-top: 50px;
          &-content {
            @include font-init(20px,#000,500);
            @include flex-row(center);
            width: 130px;
            height: 100%;
            border-right: 1px solid #000
          }
          &-icon {
            flex-grow: 1;
            &-img {
              width: 25px;
            }
          }
        }
      }
      &--right {
        @include flex-row();
        // width: 50%;
        height: 600px;
        .right__iamge {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
