var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "clo-product-app--wrapper" }, [
    _c("div", { staticClass: "clo-product-app__bar" }),
    _c("div", { staticClass: "clo-product-app" }, [
      _c(
        "div",
        { staticClass: "clo-product-app__content" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "clo-product-app__content--right" }, [
                  _c("img", {
                    directives: [
                      { name: "imageError", rawName: "v-imageError" }
                    ],
                    staticClass: "right__iamge",
                    staticStyle: { "margin-right": "20px" },
                    attrs: { src: _vm.BossPhone1, alt: "" }
                  }),
                  _c("img", {
                    directives: [
                      { name: "imageError", rawName: "v-imageError" }
                    ],
                    staticClass: "right__iamge",
                    attrs: { src: _vm.BossPhone2, alt: "" }
                  })
                ])
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "clo-product-app__content--left" }, [
                  _c("div", { staticClass: "left__title" }, [
                    _c("div", { staticClass: "left__title--subtitle" }, [
                      _vm._v("APP | 老 | 板 | 端")
                    ])
                  ]),
                  _c("div", { staticClass: "left__content" }, [
                    _c("div", [
                      _vm._v(
                        "云销宝能为你带来：强大的销售分析统计；客户、业务员何"
                      )
                    ]),
                    _c("div", [
                      _vm._v(
                        "时下单，何时付款，一键追踪。每日，每月销售数据精准统"
                      )
                    ]),
                    _c("div", [
                      _vm._v(
                        "计。从此不用来回查账，在家实时一键查询；完善的库存管"
                      )
                    ]),
                    _c("div", [
                      _vm._v(
                        "理系统：让所有的进出库记录有迹可查，帮您实现足不出户"
                      )
                    ]),
                    _c("div", [_vm._v("轻松管理； ")]),
                    _c("div", [
                      _vm._v(
                        "精准的人员定位，追踪能力，无须亲自出门，即可了解业务"
                      )
                    ]),
                    _c("div", [
                      _vm._v("员所在位置，今日行径路线，轻松管理在外业务员；")
                    ]),
                    _c("div", [
                      _vm._v(
                        "一店一品一价功能，帮助记录每个店铺不同商品的售价，实"
                      )
                    ]),
                    _c("div", [_vm._v("现傻瓜式售卖；")]),
                    _c("div", [
                      _vm._v(
                        "云销宝帮您永不丢单、漏单，实现轻松管理。我们保证数据"
                      )
                    ]),
                    _c("div", [_vm._v("保存。助您实现无纸化线上办公。")])
                  ]),
                  _c("div", { staticClass: "left__detail" }, [
                    _c("div", { staticClass: "left__detail-content" }, [
                      _vm._v("查看详情")
                    ]),
                    _c("div", { staticClass: "left__detail-icon" }, [
                      _c("img", {
                        staticClass: "left__detail-icon-img",
                        attrs: { src: _vm.ArrowsRight, alt: "" }
                      })
                    ])
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }