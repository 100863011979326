<template>
  <div class="clo-about-us--wrapper">
    <div class="clo-about-us">
      <div class="clo-about-us__content">
        <div class="clo-about-us__shadow"></div>
        <el-row>
          <el-col :span="14">
            <div class="clo-about-us__content--left">
              <div style="display: flex;">
                <div class="left__title">
                  <div class="left__title--english">
                    ABOUT<span>US</span>
                  </div>
                  <div class="left__title--subtitle">关 | 于 | 我 | 们</div>
                  <div class="left__title--line"></div>
                </div>
                <div class="left__content" style="margin-left: 120px;">
                  <div>我们可以帮您实现......</div>
                  <div>1.老板的遥控指挥</div>
                  <div>2.商品的销量分析</div>
                  <div>3.业务员的盈利对比</div>
                  <div>4.客户的销量统计</div>
                  <div>5.往来账的实时监控</div>
                  <div>6.促销政策的智能管控</div>
                  <div>7.费用的精准把握</div>
                  <div>8.现场打印和订单无纸化分享</div>
                  <div>9.工资的实时核算</div>
                  <div>10.智能的价格管理体系</div>
                </div>
              </div>
              <div class="left__content">
                <div class="pos-re" style="margin-top: 25px;">
                  <div class="abs-dot grey-dot"></div>
                  <div>客户的位置、门头照片、欠款上限、一店一品一价、导航集于一身</div>
                </div>
                <div class="pos-re">
                  <div class="abs-dot grey-dot"></div>
                  <div>仓库商品的数量价值、临期、过期、生产日期图片的实时显示和杏询</div>
                </div>
                <div class="pos-re">
                  <div class="abs-dot grey-dot"></div>
                  <div>业务员的考勒工资拜访明细、实时路线、销量分析一键查询</div>
                </div>
                <div class="pos-re">
                  <div class="abs-dot grey-dot"></div>
                  <div>财务数据的精准分析、多维度的查看、准确的报表，帮您做准确的判断</div>
                </div>
                <!-- <div>货郎日记APP依托互联网强势推出账号独立、云寄存数据永远不丢失，主</div>
                <div>要是帮助经销商管理进销存、业务员、厂家、客户，保证你的仓库库存</div>
                <div>准确，保证业务员的工作时间和效率，保证厂家的订单准确，保证客户</div>
                <div>的销售数据统计准确，规范你的财务数据，规范你的公司管理的现在市</div>
                <div>场上先进、可靠、智能的一款分销管理软件。</div> -->
              </div>
              <div class="left__number" style="margin-top: 20px">
                <div class="left__number--content">
                  <img class="icon__image" :src="Number01" alt="">
                </div>
                <div class="left__number--line"></div>
              </div>
            </div>
          </el-col>
          <el-col :span="10">
            <div class="clo-about-us__content--right">
              <img class="right__iamge" :src="AboutUs" alt="" v-imageError>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="clo-about-us__box--bottom">
        <div class="bottom__content">
          <img class="icon__image" :src="Number02" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AboutUs from '@/assets/exhibition/about-us.jpg';
import Number01 from '@/assets/index/01@2x.png';
import Number02 from '@/assets/index/02@2x.png';

export default {
  data() {
    return {
      AboutUs,
      Number01,
      Number02,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo-about-us--wrapper {
  @include flex-row(center);
  margin: 150px 0 80px 0;
  width: 100%;
  height: 650px;
  .clo-about-us {
    width: 1200px;
    height: 100%;
    &__content {
      width: 100%;
      height: 500px;
      position: relative;
      .clo-about-us__shadow {
        width: 317px;
        height: 500px;
        background-color: rgba($color: #000000, $alpha: 0.2);
        position: absolute;
        top: 0;
        right: 500px;
      }
      &--left {
        @include flex-column(space-between,flex-start);
        height: 500px;
        .left__title {
          &--english {
            @include font-init(53px,#333333,800);
            span {
              @include font-init(53px,#6B6B6B,300);
              margin-left: 15px;
            }
          }
          &--subtitle {
            @include font-init(24px,#333333,800);
            text-align: left;
            margin-top: 13px;
          }
          &--line {
            width: 60px;
            height: 2px;
            background-color: #333;
            margin-top: 35px;
          }
        }
        .left__content {
          @include font-init(18px,#6B6B6B,500);
          text-align: left;
          div {
            margin-top: 7px;
          }
        }
        .left__number {
          margin-top: 90px;
          &--content {
            @include font-init(70px,#333,$ff:'Impact Regular, Impact Regular-Regular;');
            margin-left: 40px;
            width: 60px;
            height: 59px;
            .icon__image {
              width: 100%;
              height: 100%;
            }
          }
          &--line {
            width: 100px;
            height: 2px;
            background: #333;
            margin-top: 19px;
          }
        }
      }
      &--right {
        width: 500px;
        height: 500px;
        .right__iamge {
          width: 100%;
          height: 100%;
        }
      }
    }
    &__box--bottom {
        @include flex-row(flex-end, flex-end);
      width: 100%;
      height: 150px;
      border-right: 2px solid #333;
      .bottom__content {
        @include font-init(70px,#333,$ff: 'Impact Regular, Impact Regular-Regular');
        height: 100%;
        text-align: left;
        margin-right: 20px;
        width: 69px;
        height: 59px;
        .icon__image {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
