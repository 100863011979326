var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "clo-sub-top--wrapper" }, [
    _c("div", { staticClass: "clo-sub-top" }, [
      _vm._m(0),
      _c("div", { staticClass: "clo-sub-top__entrance" }, [
        _c(
          "div",
          {
            staticClass: "clo-sub-top__entrance-manufacturer",
            on: {
              click: function($event) {
                return _vm.goEntrance(0)
              }
            }
          },
          [_vm._v("生产商登录")]
        ),
        _c(
          "div",
          {
            staticClass: "clo-sub-top__entrance-company",
            on: {
              click: function($event) {
                return _vm.goEntrance(1)
              }
            }
          },
          [_vm._v("经销商登录")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "clo-sub-top__tel" }, [
      _c("div", { staticClass: "clo-sub-top__tel-business" }, [
        _vm._v("业务电话：15653337788")
      ]),
      _c("div", { staticClass: "clo-sub-top__tel-service" }, [
        _vm._v("客服电话：0576-89881088")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }