<template>
  <div class="clo-product-app--wrapper">
    <div class="clo-product-app__bar"></div>
    <div class="clo-product-app">
      <div class="clo-product-app__content">
        <el-row>
          <el-col :span="12">
            <div class="clo-product-app__content--right">
              <img class="right__iamge" :src="BossPhone1" alt="" v-imageError style="margin-right:20px">
              <img class="right__iamge" :src="BossPhone2" alt="" v-imageError>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="clo-product-app__content--left">
              <div class="left__title">
                <div class="left__title--subtitle">APP | 老 | 板 | 端</div>
              </div>
              <div class="left__content">
                <div>云销宝能为你带来：强大的销售分析统计；客户、业务员何</div>
                <div>时下单，何时付款，一键追踪。每日，每月销售数据精准统</div>
                <div>计。从此不用来回查账，在家实时一键查询；完善的库存管</div>
                <div>理系统：让所有的进出库记录有迹可查，帮您实现足不出户</div>
                <div>轻松管理； </div>
                <div>精准的人员定位，追踪能力，无须亲自出门，即可了解业务</div>
                <div>员所在位置，今日行径路线，轻松管理在外业务员；</div>
                <div>一店一品一价功能，帮助记录每个店铺不同商品的售价，实</div>
                <div>现傻瓜式售卖；</div>
                <div>云销宝帮您永不丢单、漏单，实现轻松管理。我们保证数据</div>
                <div>保存。助您实现无纸化线上办公。</div>
              </div>
              <div class="left__detail">
                <div class="left__detail-content">查看详情</div>
                <div class="left__detail-icon">
                  <img class="left__detail-icon-img" :src="ArrowsRight" alt="">
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowsRight from '@/assets/common/arrows-right.png';
import BossPhone1 from '@/assets/exhibition/boss-phone1.png';
import BossPhone2 from '@/assets/exhibition/boss-phone2.png';

export default {
  data() {
    return {
      ArrowsRight,
      BossPhone1,
      BossPhone2,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo-product-app--wrapper {
  @include flex-row(center);
  margin: 150px 0 80px 0;
  width: 100%;
  height: 600px;
  position: relative;
  .clo-product-app__bar {
    width: 50%;
    height: 300px;
    background-color: #F39800;
    position: absolute;
    top: 150px;
    left: 0;
  }
  .clo-product-app {
    width: 1200px;
    height: 100%;
    &__content {
      width: 100%;
      height: 500px;
      position: relative;
      &--left {
        @include flex-column(space-between,flex-end);
        height: 600px;
        padding: 70px 0;
        .left__title {
          &--subtitle {
            @include font-init(23px,#333333,800);
            text-align: left;
            margin-top: 18px;
          }
        }
        .left__content {
          @include font-init(18px,#6B6B6B,500);
          // margin-bottom: 100px;
          word-spacing: -1;
          text-align: left;
          div {
            margin-top: 7px;
            text-align: right
          }
        }
        .left__detail {
          @include flex-row(center);
          width: 170px;
          height: 40px;
          border: 1px solid #535353;
          margin-top: 50px;
          &-content {
            @include font-init(20px,#000,500);
            @include flex-row(center);
            width: 130px;
            height: 100%;
            border-right: 1px solid #000
          }
          &-icon {
            flex-grow: 1;
            &-img {
              width: 25px;
            }
          }
        }
      }
      &--right {
        @include flex-row();
        // width: 50%;
        height: 600px;
        .right__iamge {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
