var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "clo-product-app--wrapper" }, [
    _c("div", { staticClass: "clo-product-app" }, [
      _c(
        "div",
        { staticClass: "clo-product-app__content" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "clo-product-app__content--left" }, [
                  _c("div", { staticClass: "left__title" }, [
                    _c("div", { staticClass: "left__title--subtitle" }, [
                      _vm._v("厂 | 商 | 端")
                    ])
                  ]),
                  _c("div", { staticClass: "left__content" }, [
                    _c("div", [
                      _vm._v(
                        "1.及时掌握经销商的库存和销售情况，不能简单粗暴的压货。"
                      )
                    ]),
                    _c("div", [
                      _vm._v(
                        "2.积极调整销售策略，开发更多能让你的合作伙伴和员工赚钱的产品"
                      )
                    ]),
                    _c("div", [_vm._v("和方式。")]),
                    _c("div", [
                      _vm._v(
                        "3.了解你的经销商的财务状况，不要只是对上预存款，对下是赊欠+退"
                      )
                    ]),
                    _c("div", [
                      _vm._v(
                        "货+死账+垫付费用+营销所产生的各种费用，最终把经销商压死了。"
                      )
                    ]),
                    _c("div", [
                      _vm._v(
                        "4.不要让经销商给你虚假的数据，我们要真实的汇总数据来判断我们"
                      )
                    ]),
                    _c("div", [_vm._v("的产品走向。")])
                  ]),
                  _c("div", { staticClass: "left__detail" }, [
                    _c("div", { staticClass: "left__detail-content" }, [
                      _vm._v("查看详情")
                    ]),
                    _c("div", { staticClass: "left__detail-icon" }, [
                      _c("img", {
                        staticClass: "left__detail-icon-img",
                        attrs: { src: _vm.ArrowsRight, alt: "" }
                      })
                    ])
                  ])
                ])
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "clo-product-app__content--right" }, [
                  _c("img", {
                    directives: [
                      { name: "imageError", rawName: "v-imageError" }
                    ],
                    staticClass: "right__iamge",
                    attrs: { src: _vm.Manufacturer, alt: "" }
                  })
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }