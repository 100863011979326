var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "clo-product-app--wrapper" }, [
    _c("div", { staticClass: "clo-product-app__bar" }),
    _c("div", { staticClass: "clo-product-app" }, [
      _c(
        "div",
        { staticClass: "clo-product-app__content" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "clo-product-app__content--right" }, [
                  _c("img", {
                    directives: [
                      { name: "imageError", rawName: "v-imageError" }
                    ],
                    staticClass: "right__iamge",
                    staticStyle: { "margin-right": "20px" },
                    attrs: { src: _vm.LittleProgram1, alt: "" }
                  }),
                  _c("img", {
                    directives: [
                      { name: "imageError", rawName: "v-imageError" }
                    ],
                    staticClass: "right__iamge",
                    attrs: { src: _vm.LittleProgram2, alt: "" }
                  })
                ])
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "clo-product-app__content--left" }, [
                  _c("div", { staticClass: "left__title" }, [
                    _c("div", { staticClass: "left__title--subtitle" }, [
                      _vm._v("客 | 户 | 小 | 程 | 序")
                    ])
                  ]),
                  _c("div", { staticClass: "left__content" }, [
                    _c("div", [
                      _vm._v(
                        "把自己的店铺开到微信上，下游客户直接打开微信就可以进"
                      )
                    ]),
                    _c("div", [_vm._v("入店铺下单，这简直就是网购式订货.")]),
                    _c("div", [_vm._v("我们可以做的无纸化办公;")]),
                    _c("div", [
                      _vm._v(
                        "客户的电子单据可以永久的保存,永远不丢失,省去了纸质单"
                      )
                    ]),
                    _c("div", [
                      _vm._v(
                        "据的不好储存的问题,你也可以让你的客户扫下面的小程序码"
                      )
                    ]),
                    _c("div", [_vm._v("直接扫码加入。")])
                  ]),
                  _c("div", { staticClass: "left__detail" }, [
                    _c("div", { staticClass: "left__detail-content" }, [
                      _vm._v("查看详情")
                    ]),
                    _c("div", { staticClass: "left__detail-icon" }, [
                      _c("img", {
                        staticClass: "left__detail-icon-img",
                        attrs: { src: _vm.ArrowsRight, alt: "" }
                      })
                    ])
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }