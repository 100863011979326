var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "clo-company-history--wrapper" }, [
    _c("div", { staticClass: "clo-company-history" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "clo-company-history__content" },
        [
          _c("div", { staticClass: "clo-company-history__shadow" }),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "clo-company-history__content--left" },
                  [
                    _c("div", { staticClass: "left__number" }, [
                      _c("div", { staticClass: "left__number--content" }, [
                        _c("img", {
                          staticClass: "icon__image",
                          attrs: { src: _vm.dataObj.index, alt: "" }
                        })
                      ]),
                      _c("div", { staticClass: "left__number--line" })
                    ]),
                    _c("div", { staticClass: "left__title" }, [
                      _c("div", { staticClass: "left__title--subtitle" }, [
                        _vm._v(_vm._s(_vm.dataObj.title))
                      ])
                    ]),
                    _c("div", { staticClass: "left__content" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "text-indent": "2em",
                            height: "140px",
                            "line-height": "1.3em"
                          }
                        },
                        [_vm._v(_vm._s(_vm.dataObj.desc))]
                      )
                    ])
                  ]
                )
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "clo-company-history__content--right" },
                  [
                    _c("img", {
                      directives: [
                        { name: "imageError", rawName: "v-imageError" }
                      ],
                      staticClass: "right__iamge",
                      attrs: { src: _vm.dataObj.img, alt: "" }
                    })
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "clo-company-history-list" }, [
      _c(
        "div",
        { staticClass: "clo-company-history-list__image-list" },
        _vm._l(_vm.historyList, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "clo-company-history-list__item",
              on: {
                click: function($event) {
                  return _vm.resetActive(index)
                }
              }
            },
            [
              _c("img", {
                staticClass: "item__image",
                attrs: { src: item.img, alt: "" }
              }),
              _vm.active !== index
                ? _c("div", { staticClass: "item__shadow" })
                : _vm._e(),
              _vm.active !== index
                ? _c("div", { staticClass: "item__title" }, [
                    _vm._v(_vm._s(item.title))
                  ])
                : _vm._e()
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "clo-company-history-list__title-list" },
        _vm._l(_vm.historyList, function(item, index) {
          return _c("div", {
            key: index,
            staticClass: "barItem",
            class: { select: _vm.active === index }
          })
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "clo-company-history__title" }, [
      _c("div", { staticClass: "clo-company-history__title--english" }, [
        _vm._v(" COMPANY"),
        _c("span", [_vm._v("HISTORY")])
      ]),
      _c("div", { staticClass: "clo-company-history__title--subtitle" }, [
        _c("div", { staticClass: "subtitle__line" }),
        _c("div", { staticClass: "subtitle__text" }, [
          _vm._v("公 | 司 | 历 | 程 | 与 | 展 | 示")
        ]),
        _c("div", { staticClass: "subtitle__line" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }