var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "clo-product-app--wrapper" }, [
    _c("div", { staticClass: "clo-product-app__bar" }),
    _c("div", { staticClass: "clo-product-app" }, [
      _c(
        "div",
        { staticClass: "clo-product-app__content" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "clo-product-app__content--right" }, [
                  _c("img", {
                    directives: [
                      { name: "imageError", rawName: "v-imageError" }
                    ],
                    staticClass: "right__iamge",
                    staticStyle: { "margin-right": "20px" },
                    attrs: { src: _vm.StaffPhone1, alt: "" }
                  }),
                  _c("img", {
                    directives: [
                      { name: "imageError", rawName: "v-imageError" }
                    ],
                    staticClass: "right__iamge",
                    attrs: { src: _vm.StaffPhone2, alt: "" }
                  })
                ])
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "clo-product-app__content--left" }, [
                  _c("div", { staticClass: "left__title" }, [
                    _c("div", { staticClass: "left__title--subtitle" }, [
                      _vm._v("APP | 业 | 务 | 员")
                    ])
                  ]),
                  _c("div", { staticClass: "left__content" }, [
                    _c("div", [_vm._v("定位考勤，巡店拍照")]),
                    _c("div", [_vm._v("傻瓜式销售")]),
                    _c("div", [_vm._v("智能交款")]),
                    _c("div", [_vm._v("手机端下订单")]),
                    _c("div", [_vm._v("库存变化")]),
                    _c("div", [_vm._v("盘点")]),
                    _c("div", [_vm._v("品项价格记忆")]),
                    _c("div", [_vm._v("查看线路规划")]),
                    _c("div", [_vm._v("交易查询")])
                  ]),
                  _c("div", { staticClass: "left__detail" }, [
                    _c("div", { staticClass: "left__detail-content" }, [
                      _vm._v("查看详情")
                    ]),
                    _c("div", { staticClass: "left__detail-icon" }, [
                      _c("img", {
                        staticClass: "left__detail-icon-img",
                        attrs: { src: _vm.ArrowsRight, alt: "" }
                      })
                    ])
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }